<template>
    <div class="profile mt-0 mb-5 md:mb-0 p-5 md:p-0 bg-white md:bg-transparent">
        <h2 class="text-2xl md:text-3xl font-bold mb-4 border-green-300 border-b pb-4">{{ title }}</h2>
        <p>{{ text }}</p>
    </div>
</template>

<script>
    export default {
        name: "TextArea",
        props: ['title','text'],
        data() {},
    };
</script>