import image from "../assets/profile.jpg"

export default {
    profile: {
        name: "Tom Aylott",
        role: "Front End Developer",
        dob: "12/04/1988",
        tel: "+44 (0) 7545915300",
        email: "tomaylott@gmail.com",
        image: image,
        text: 'Front End Developer with over 8 years of experience working in a range of platforms, programming languages, and industries. I also marketing experience from roles in the music industry, and combine the two as a strong all rounder for digital / entertainment positions. I\'m always ready to bring new ideas to the table as well as change my own axioms, and will always push myself and my team to be the very best they can be.'
    },
    about: {
        text: 'This page was created using Vue.js and Tailwind, built with webpack, and deployed with GitHub and Netlify. The data is held in JSON within the project repo but could just as easily be managed from a separate CMS. If you\'d like to see a copy of the source code, please let me know.'
    },
    experience: [
        {

            company: 'Uprated',
            position: 'Technical Lead',
            startDate: 'Mar 2021',
            endDate: 'Present',
            achievements: [
                {
                    text: 'Improved build workflow to reduce production CSS and JavaScript size by over 50% across company sites and reduce project build times'
                },
                {
                    text: 'Built a website on Craft CMS that returns a lighthouse score of 99 in performance and 100 in Best Practices'
                },
                {
                    text: 'Developed a base Shopify Online Store 2.0 theme to move the company from a "buy and customise" model to bespoke stores'
                }
            ]

        },
        {

            company: 'Plectrumm',
            position: 'Web Developer',
            startDate: 'Mar 2019',
            endDate: 'Present',
            achievements: [
                {
                    text: 'Built a Storyblok CMS based system that hosts over 200 campaign pages'
                },
                {
                    text: 'Used Craft and Wordpress to deliver high performance websites for internationally reknowned artists'
                },
                {
                    text: 'Implemented kanban / agile approach for development woflows to improve company productivity'
                },
                {
                    text: 'Created bespoke single page apps for high impact campaigns'
                }
            ]

        },
        {

            company: 'Intergage',
            position: 'Lead Front End Developer',
            startDate: 'Feb 2018',
            endDate: 'Jul 2020',
            achievements: [
                {
                    text: 'Built and maintained hundreds of websites on the in-house Intergage CMS, Wordpress and Shopify'
                },
                {
                    text: 'Trained junior members of staff in best practices for front end development'
                },
                {
                    text: 'Managed monthly development workflows for existing clients'
                },
                {
                    text: 'Consult with development team about improvements to the in-house CMS UX/DX'
                }
            ]

        },
        {

            company: 'Pink Mist',
            position: 'Digital Marketer',
            startDate: 'Mar 2015',
            endDate: 'Jan 2018',
            achievements: [
                {
                    text: 'Managed announcements across the company social media channels'
                },
                {
                    text: 'Built and designed marketing emails in Mailchimp '
                },
                {
                    text: 'Reported ticket sales to various stakeholders weekly'
                },
                {
                    text: 'Managed and helped to manage events at a range of iconic London venues such as Electric Ballroom, Koko and The Old Blue last'
                }
            ]

        },
        {

            company: 'Hopeless Records',
            position: 'In-house PR and Digital Marketing Assistant',
            startDate: 'Jan 2015',
            endDate: 'May 2017',
            achievements: [
                {
                    text: 'Arranged features / exclusives for the label\'s bands with Radio One, Kerrang!, Rock Sound and other national publications'
                },
                {
                    text: 'Worked on a number one album campaign with All Time Low\'s "Future Hearts'
                },
                {
                    text: 'Managed in-person press schedules on tours and at major festivals such as Reading / Leeds, Download and Slam Dunk Festival'
                },
                {
                    text: 'Helped with the overall UK digital strategy and positioning of the label'
                }
            ]
        },
        {
            company: 'Punktastic.com',
            position: 'Contributor then Editor',
            startDate: '2009',
            endDate: '2014',
            achievements: [
                {
                    text: 'Curated stages at festivals in the UK and abroad, including Slam Dunk Festival, Groezrock Festival, Hevy Festival, as well as promoting regular events in London under the brand name'
                },
                {
                    text: 'Managed a 30+ team of contributors, of which many have since gone on to work in senior roles across the music industry'
                },
                {
                    text: 'Interviewed renowned international rock/metal/punk artists such as blink-182, AFI, Fall Out Boy, A Day To Remember and Sum 41 and appeared on BBC Radio 1 as a guest multiple times to recommend new music'
                }
            ]
        },
        {

            company: 'Mediazoo',
            position: 'eLearning Developer / Web Developer',
            startDate: 'May 2014',
            endDate: 'Jan 2015'
        },
        {

            company: 'Rightster',
            position: 'Content Operations Coordinator',
            startDate: 'Jul 2012',
            endDate: 'Aug 2013'
        },
        {

            company: 'Baker Tilly',
            position: 'eLearning Developer',
            startDate: 'Aug 2009',
            endDate: 'Nov 2011'
        },
        {

            company: 'Brightwave',
            position: 'eLearning Developer',
            startDate: 'Aug 2007',
            endDate: 'Jul 2009'
        }
        
    ],
    education: [
        {
            id: 0,
            school: 'BIMM',
            qualification: 'Higher Diploma',
            grade: 'Modern Commercial Music - Pass',
            startDate: 'September 2016',
            endDate: 'June 2017'
        },
        {
            id: 1,
            school: 'Thomas Hardye School',
            qualification: 'A Levels',
            grade: '4 x A-D Passing Grades',
            startDate: 'September 2014',
            endDate: 'June 2016'
        },
        {
            id: 2,
            school: 'Thomas Hardye School',
            qualification: 'GCSEs',
            grade: '12 x A*-C Passing Grades',
            startDate: 'September 2012',
            endDate: 'June 2014'
        }
    ],
    skills: [
        {
            type: "HTML/CSS",
            strength: 5
        },
        {
            type: "Javascript",
            strength: 5
        },
        {
            type: "Twig / Liquid",
            strength: 5
        },
        {
            type: "Vue",
            strength: 4
        },
        {
            type: "NPM / Node.js",
            strength: 4
        },
        {
            type: "PHP / Composer",
            strength: 4
        },
        {
            type: "UX Testing / Implementation",
            strength: 4
        },
        {
            type: "Webpack / Gulp",
            strength: 4
        },
        {
            type: "React",
            strength: 3
        },
        {
            type: "Git",
            strength: 3
        }
    ],
    work: [
        {
            name: "Built For Mars",
            url: "https://builtformars.com",
            description: "Website built on Craft CMS with Twig / MailChimp integrations"
        },
        {
            name: "Fontaines D.C",
            url: "https://fontainesdc.com",
            description: "Artist website built with Craft CMS"
        },
        {
            name: "Miles Kane",
            url: "https://mileskane.com",
            description: "Artist website built with Craft CMS"
        }
    ]
}