<template>
    <div class="mt-5 mb-5 md:mb-0 p-5 md:p-0 bg-white md:bg-transparent">
        <h2 class="text-2xl md:text-3xl font-bold mb-4 border-green-300 border-b pb-4">Experience</h2>
        <Job v-for="(value, index) in experience" :job="value" :key="index"></Job>
    </div>
</template>


<script>
    import Job from './Job.vue';
    export default {
        name: "Experience",
        props: ['experience'],
        data() {},
        components: {
            Job
        }
    };
</script>