<template>
    <div class="mt-5 mb-5 md:mb-0 p-5 md:p-0 bg-white md:bg-transparent">
        <h2 class="text-2xl md:text-3xl font-bold mb-4 border-green-300 border-b pb-4">Work Examples</h2>
        <div class="mb-4" v-for="(item,index) in work" :key="index">
            <h3 class="font-bold text-xl">{{ item.name }}</h3>
            <p class="italic">{{ item.description }}</p>
            <a :href="item.url" class="underline" rel="noopener">{{ item.url }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Work",
        props: ['work'],
        data() {}
    };
</script>