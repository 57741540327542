<template>
  <div class="bg-gray-100">
    <div class="xl:container mx-auto">
      <div class="relative md:flex md:flex-wrap">
        <div class="md:sticky md:self-start md:top-0 sidebar w-full md:w-1/3 lg:w-1/4 md:pr-5 order-last md:order-first">
          <Header :profile="profile"/>
        </div>
        <div class="main-content w-full md:w-2/3 lg:w-3/4 p-5 md:bg-white">
          <TextArea title="Profile" :text="profile.text"/>
          <Experience :experience="experience"/>
          <Education :education="education"/> 
          <Skills :skills="skills"/>
          <Work :work="work"/>
          <div class="md:mt-5">
            <TextArea title="About" :text="about.text"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from './data/Data';

import Header from "./components/Header.vue";
import Experience from "./components/Experience.vue";
import Skills from "./components/Skills.vue";
import Education from "./components/Education.vue";
import TextArea from "./components/TextArea.vue";
import Work from "./components/Work.vue";

export default {
  name: "App",
  data() {
    return {
      profile:data.profile,
      education:data.education,
      experience:data.experience,
      skills:data.skills,
      about:data.about,
      work:data.work
    }
  },
  components: {
    Skills, 
    Experience,
    Header,
    Education,
    TextArea,
    Work
  },
};
</script>