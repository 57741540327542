<template>
     <div class="mt-5 mb-5 md:mb-0 p-5 md:p-0 bg-white md:bg-transparent">
        <h2 class="text-2xl md:text-3xl font-bold border-green-300 border-b pb-4">Education</h2>
        <div class="education-item mt-4" v-for="(item,id) in education" :key="id">
            <h3 class="text-xl font-bold education-item__school">{{ item.school }} - {{ item.qualification }}</h3>
            <p class="education-item__date italic mb-4">{{ item.startDate }} - {{ item.endDate }}</p>
            <p class="education-item__grade">{{ item.grade }}</p>
        </div>
    </div>
</template>


<script>
    export default {
        name: "Education",
        props: ['education'],
        data() {}
    };
</script>