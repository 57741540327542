<template>
    <div class="header">
        <div>
            <div class="header__image relative z-0">
                <img class="absoute w-full h-full max-w-full max-h-full object-cover" :src="profile.image"/>
            </div>
            <div class="md:border-green-300 md:border-b z-10 header__details p-5 mx-5 mt-5 md:m-5 md:-top-10 bg-white relative flex flex-col justify-between">
                <div>
                    <h1 class="text-3xl font-bold md:font-bold">{{ profile.name }}</h1>
                    <p class="text-xl md:font-bold">{{ profile.role }}</p>
                </div>
                <ul class="mt-4 header__details__contact w-full">
                    <li>{{ profile.tel }}</li>
                    <li>{{ profile.email }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .header__image {
        aspect-ratio:4/3;
    }
    @media (min-width:769px) {
        .header__image {
            aspect-ratio:1/1;
        }
    }
</style>


<script>

    export default {
        name: "Header",
        props: ['profile'],
        data() {}
    };
</script>