<template>
    <div class="mt-5 mb-5 md:mb-0 p-5 md:p-0 bg-white md:bg-transparent">
        <h2 class="text-2xl md:text-3xl font-bold mb-4 border-green-300 border-b pb-4">Skills</h2>
        <div class="mb-4" v-for="(item,index) in skills" :key="index">
            <p class="font-bold mb-2">{{ item.type }}</p>
            <div class="strength flex w-full">
                <div class="block w-1/5 bg-green-300 h-2 border-r-2 border-white" v-for="index in item.strength" :key="index">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Skills",
        props: ['skills'],
        data() {}
    };
</script>