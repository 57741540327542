<template>
  <div class="mb-4">
    <h3 class="text-xl font-bold">{{ job.company }} - {{ job.position }}</h3>
    <p class="mb-4 italic">{{ job.startDate }} - {{ job.endDate }}</p>
    <ul class="list-disc ml-10" v-if="job.achievements">
        <li class="mb-2" v-for="(achievement, index) in job.achievements" :key="index">
            {{ achievement.text }}
        </li>
    </ul>
  </div>
</template>


<script>
    export default {
        name: "Job",
        props: ["job"],
        data() {
            return {}
        },
        methods: {},
    };
</script>